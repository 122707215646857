/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // Npm: Apollo client for handling graphql request.

/*
 * GRAPHS
 */
const Index = gql`
query AccountVerificationQuery {
  AccountVerification {
    message
    status
    verified
  }
}
`;

/*
 * EXPORTS
 */
export default Index;
